import React from 'react';
import './bio.css';

const Bio = () => (
  <div className='flex-grid'>
    <div className='col'>
      <img
        className='logo-image responsive'
        src={'/anthonyanader-400x400.jpg'}
        alt='Anthony Logo'
      />
    </div>
    <div className='col'>
      <p className='bio-text'>
        <span classNam='anthony-intro'>
          <a
            className='anchor'
            href='https://twitter.com/anthonyanader'
            target='_blank'
            rel='noreferrer'
          >
            Hey, I'm Anthony
          </a>
        </span>
        . I'm a software engineer by ☀️ and music producer at 🌑 based in
        Montreal.
        <br></br>
        <br></br>
        Before you go, if you happen to be a reseller, my team and I are
        currently building{' '}
        <span>
          <a
            className='anchor'
            href='https://pricely.io'
            target='_blank'
            rel='noreferrer'
          >
            Pricely
          </a>
          . You should definitely check it out!
        </span>
      </p>
    </div>
  </div>
);

export default Bio;
